//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CodeSize from '../CodeSize';
import CodeItem from './CodeItem';
import { combindCode } from './parse-data-server';
import { isValidLATLONG } from '@/utils/util';
import AddCodeFromMobile from './modals/AddCodeFromMobile';
import PreviewQRCode from './modals/PreviewQRCode.vue';
import { deepDiffMapper } from '@/utils/diff-objects';

export default {
  components: {
    CodeSize,
    CodeItem,
    AddCodeFromMobile,
    PreviewQRCode
  },
  props: {
    title: { type: String, required: true, default: '' },
    firstTime: { type: Boolean, required: true, default: true },
    category: { type: Object, default: () => ({}) },
    thumbnail: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      codes: [],
      size: '2',
      ecLevel: '1',
      loading: false,
      visible: true,
      urlData: '',
      defaultCode: null
    };
  },
  computed: {
    validateCodeName() {
      return this.codes.some(code => {
        if (code?.languages?.length === 0) return true;
        return code?.languages?.some(lang => code.name[lang] === '');
      });
    },
    validatePoints() {
      return this.codes.some(code => {
        if (code?.points?.length === 0) return true;
        return code?.points?.some(point => {
          if (code.languages.some(lang => !point[lang])) return true;
          if (!isValidLATLONG(point.LAT) || !isValidLATLONG(point.LONG)) return true;
          if (code.naviType === 0 && point.CATEGORIES.length === 0) return true;
        });
      });
    },
    disabledButton() {
      if (
        !this.title ||
        this.codes?.length === 0 ||
        this.validateCodeName ||
        this.validatePoints ||
        (this.thumbnail?.url && !this.thumbnail?.alt)
      ) {
        return true;
      }
      return false;
    },
    codeType() {
      const naviCodes = this.codes.filter(item => item.naviType === 1);
      const faciCodes = this.codes.filter(item => item.naviType === 0);
      return naviCodes.length > 0 && faciCodes.length > 0
        ? 'mix_code'
        : faciCodes.length > 0
        ? 'spot_code'
        : 'navi_code';
    },
    projectId() {
      return this.$route.params.projectId;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    code() {
      return {
        title: this.title,
        thumbnail: this.thumbnail,
        category: this.category,
        codes: this.codes,
        size: this.size,
        ecLevel: this.ecLevel
      };
    },
    isDefaultDataChanged() {
      const result = deepDiffMapper.isDiff(this.defaultCode, this.code);
      return !this.deActiveButton && result;
    }
  },
  mounted() {
    this.$emit('setFirstTime', true);
    this.defaultCode = {
      title: this.title,
      thumbnail: this.thumbnail,
      category: this.category,
      codes: this.codes,
      size: this.size,
      ecLevel: this.ecLevel
    };
  },
  methods: {
    addCode() {
      this.visible = true;
    },
    removeCode(codeKey) {
      const newCodes = this.codes.filter(item => item.key !== codeKey);
      this.codes = [...newCodes];
    },
    setLanguages({ codeKey, languages }) {
      const code = this.codes.find(item => item.key === codeKey);
      code.languages = [...languages];
    },
    setName({ codeKey, key, value }) {
      const newCodes = [...this.codes];
      const code = newCodes.find(item => item.key === codeKey);
      code.name = { ...code.name, [key]: value };
      this.codes = [...newCodes];
    },
    setType({ codeKey, value }) {
      const code = this.codes.find(item => item.key === codeKey);
      code.naviType = value;
      code.points = [];
    },
    setPoints({ codeKey, newPoints }) {
      const code = this.codes.find(item => item.key === codeKey);
      code.points = [...newPoints];
    },
    changeSize(value) {
      this.size = value;
    },
    changeEcLevel(value) {
      this.ecLevel = value;
    },
    saveCode() {
      const statusCode = 'DRAFT';
      this.submitForm(statusCode);
    },
    generateCode() {
      const statusCode = 'GENERATE';
      this.submitForm(statusCode);
    },
    async submitForm(statusCode) {
      const input = {
        status: statusCode,
        codes: combindCode(this.codes),
        title: this.title,
        code_type: this.codeType,
        sym_size: this.size,
        sym_ec_level: this.ecLevel,
        category_id: this.category.id,
        thumbnail: this.thumbnail
      };
      this.$ls.set('confirm-leave', true);
      try {
        this.loading = true;
        await this.$s.api.page.createCodes(this.pageId, input);
        this.notify(statusCode, `${this.$t('Code name')}: ${this.title}`);
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t(error.message)
        });
      } finally {
        this.loading = false;
      }
    },
    setPointsImport({ type, points, title }) {
      this.$emit('setTitle', title);
      if (points.length > 0) {
        let key = this.codes.length;
        const newCodes = points.reduce((accCodes, item) => {
          key++;
          const newPoints = item.reduce((acc, point, index) => {
            const newPoint = Object.assign(
              {
                key: index + 1,
                JPN: point.name,
                LAT: point.lat,
                LONG: point.lon,
                ENG: '',
                KOR: '',
                CHI: '',
                INFO_ENG: '',
                INFO_JPN: '',
                INFO_KOR: '',
                INFO_CHI: ''
              },
              type === 0 ? { CATEGORIES: [] } : {}
            );
            return acc.concat(newPoint);
          }, []);
          const code = {
            key: key,
            naviType: type,
            codeName: `Code #${key}`,
            languages: ['JPN'],
            name: {
              ENG: '',
              JPN: title,
              KOR: '',
              CHI: ''
            },
            points: [...newPoints]
          };
          return accCodes.concat(code);
        }, []);
        this.codes = [...this.codes, ...newCodes];
      } else {
        const key = this.codes.length + 1;
        const item = {
          key: key,
          codeName: `Code #${key}`,
          naviType: type,
          languages: ['JPN'],
          name: {
            ENG: '',
            JPN: title,
            KOR: '',
            CHI: ''
          },
          points: [
            Object.assign(
              {
                key: 0,
                JPN: '',
                LAT: '',
                LONG: '',
                ENG: '',
                KOR: '',
                CHI: '',
                INFO_ENG: '',
                INFO_JPN: '',
                INFO_KOR: '',
                INFO_CHI: ''
              },
              type === 0 ? { CATEGORIES: [] } : {}
            )
          ]
        };
        this.codes = [...this.codes, item];
      }

      this.visible = false;
    },
    closeAddPoint() {
      this.visible = false;
      this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
    },
    notify(type = 'DRAFT', description = '') {
      const config = {
        DRAFT: {
          message: this.$t('SaveDraftSuccess'),
          description
        },
        GENERATE: {
          message: this.$t('SaveGenerateSuccess'),
          description
        },
        PUBLISHED: {
          message: this.$t('SaveGenerateSuccess'),
          description
        },
        ABOLISHED: {
          message: this.$t('SaveAbolishedSuccess'),
          description
        },
        PENDING: {
          message: this.$t('SaveGenerateSuccess'),
          description
        }
      };
      return new Promise(resolve => {
        setTimeout(() => {
          this.$notification.success(config[type]);
        }, 100);
        resolve(0);
      });
    },
    goToImportFile() {
      this.visible = false;
      this.$emit('setCurrentTab', '2');
    },
    previewQRCode() {
      const data = {
        codes: combindCode(this.codes),
        sym_size: this.size,
        sym_ec_level: this.ecLevel
      };
      this.$refs.previewQRRef.handlePreview(data);
    }
  }
};
