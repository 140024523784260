var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "block--code-size" },
        [
          _c("code-size", {
            attrs: {
              size: _vm.size,
              ecLevel: _vm.ecLevel,
              codeStatus: "DRAFT"
            },
            on: {
              onChangeSize: _vm.changeSize,
              onChangeEcLevel: _vm.changeEcLevel
            }
          })
        ],
        1
      ),
      _c("a-divider", { attrs: { dashed: "" } }),
      _c(
        "div",
        { staticClass: "block--code-list" },
        [
          _c("a-list", {
            attrs: {
              "data-source": _vm.codes,
              grid: { gutter: 16, xs: 1, sm: 1, md: 1, lg: 1 },
              locale: {
                emptyText: _vm.$t("No data")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "renderItem",
                fn: function(item) {
                  return _c(
                    "a-list-item",
                    {},
                    [
                      _c("code-item", {
                        attrs: { item: item, codeStatus: "DRAFT" },
                        on: {
                          setName: _vm.setName,
                          remove: _vm.removeCode,
                          setType: _vm.setType,
                          setLanguages: _vm.setLanguages,
                          setPoints: _vm.setPoints
                        }
                      })
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-end mt-sm" },
        [
          _c("a-button", { on: { click: _vm.addCode } }, [
            _vm._v(" " + _vm._s(_vm.$t("Add Code")) + " ")
          ])
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "div",
        { staticClass: "content-center my-xl" },
        [
          _c(
            "a-button",
            {
              staticClass: "button mr-xs",
              attrs: {
                disabled: _vm.disabledButton,
                type: "dashed",
                icon: "eye"
              },
              on: { click: _vm.previewQRCode }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Show demo")) + " ")]
          ),
          _c(
            "a-button",
            {
              staticClass: "button mr-xs",
              attrs: {
                ghost: "",
                type: "primary",
                icon: "save",
                loading: _vm.loading,
                disabled: _vm.disabledButton
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.saveCode($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Save as draft")) + " ")]
          ),
          !_vm.disabledButton
            ? _c(
                "a-popconfirm",
                {
                  attrs: {
                    placement: "top",
                    title: _vm.$t("Are you sure to generate code?"),
                    "ok-text": _vm.$t("Yes"),
                    "cancel-text": _vm.$t("No")
                  },
                  on: { confirm: _vm.generateCode }
                },
                [
                  _c(
                    "a-button",
                    {
                      class: {
                        button: true,
                        blink: true,
                        blinking: _vm.isDefaultDataChanged
                      },
                      attrs: { loading: _vm.loading }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "15px",
                          height: "15px",
                          "margin-right": "5px"
                        },
                        attrs: { src: require("@/assets/logo.png") }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("Generate")) + " ")
                    ]
                  )
                ],
                1
              )
            : _c(
                "a-button",
                {
                  class: {
                    button: true,
                    blink: true,
                    blinking: _vm.isDefaultDataChanged
                  },
                  attrs: { disabled: "" }
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "15px",
                      height: "15px",
                      "margin-right": "5px"
                    },
                    attrs: { src: require("@/assets/logo.png") }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("Generate")) + " ")
                ]
              )
        ],
        1
      ),
      _vm.visible
        ? _c("add-code-from-mobile", {
            attrs: {
              firstTime: _vm.firstTime,
              visible: _vm.visible,
              title: _vm.title,
              "is-created": ""
            },
            on: {
              setPointsImport: _vm.setPointsImport,
              close: _vm.closeAddPoint,
              goToImportFile: _vm.goToImportFile
            }
          })
        : _vm._e(),
      _c("PreviewQRCode", { ref: "previewQRRef" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }